import React,{Fragment ,Component} from 'react';
import {Link} from 'react-router-dom';

//images
import logo from './../../images/logo.png';
import Whatsapp from './../../images/whatsapp.png';


class Header extends Component{
	componentDidMount() {
        // sidebar open/close
        var Navicon = document.querySelector('.navicon');
        var sidebarmenu = document.querySelector('.myNavbar ');

        function toggleFunc() {
            sidebarmenu.classList.toggle('show');
            Navicon.classList.toggle('open');
        }
        Navicon.addEventListener('click', toggleFunc);
        // Sidenav li open close
        var navUl = [].slice.call(document.querySelectorAll('.navbar-nav > li'));
        for (var y = 0; y < navUl.length; y++) {
            navUl[y].addEventListener('click', function () { checkLi(this) });
        }
        function checkLi(current) {
            navUl.forEach(el => (current !== el) ? el.classList.remove('open') : '');
			setTimeout(() => {
				current.classList.toggle('open');	
			}, 100);				
        }
	}
	render(){
		return(
			<Fragment>
				<header className="site-header header mo-left">
					<div className="top-bar">
						<div className="container">
							<div className="row d-flex justify-content-between">
								<div className="dlab-topbar-left">
									<ul>
										<li><i className="fa fa-phone m-r5"></i>+91 9700700745</li>
										<li><i className="fa fa-map-marker m-r5"></i> Plot No. 150, Block A, Sector 8 Dwarka, New Delhi - 110077</li>
									</ul>
								</div>
								<div className="dlab-topbar-right">
									<ul>
										<li><i className="fa fa-clock-o m-r5"></i> Timing : 8:30am-3:30pm</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					{/* main header */}
					<div className="sticky-header main-bar-wraper navbar-expand-lg">
						<div className="main-bar clearfix ">
							<div className="container clearfix">
								{/*  website logo */} 
								<div className="logo-header mostion">
									<Link to={"./"} className="dez-page"><img src={logo} alt="" /></Link>
								</div>
								{/* nav toggle button */}
								<button className="navbar-toggler collapsed navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
									<span></span>
									<span></span>
									<span></span>
								</button>
								{/*  main nav  */}
								<div className="header-nav navbar-collapse collapse myNavbar justify-content-end" id="navbarNavDropdown">
									<div className="logo-header mostion">
										<Link to={"./"} className="dez-page"><img src={logo} alt="" /></Link>
									</div>
									<ul className="nav navbar-nav">	
										<li><Link to={"./"}>Home </Link></li>
										
										<li><Link to={"/aboutus"}>About Us</Link></li>
											
										<li><Link to={"/classes"}>Classes</Link></li>
											
										{/* <li><Link to={"/teachers"}>Teachers</Link></li> */}

										{/* <li><Link to={"/blog"}>Blog</Link></li> */}

										<li><Link to={'#'}>Blog <i className="fa fa-chevron-down"></i></Link>
											<ul className="sub-menu">
												<li><Link to={"/blog"}>Blog</Link></li>
												<li><Link to={"/education"}>Education Excursion</Link></li>
											</ul>
										</li>
										
										<li><Link to={"/gallery"}>Gallery</Link></li>

										<li><Link to={'#'}>Events & Celebrations <i className="fa fa-chevron-down"></i></Link>
											<ul className="sub-menu">
												<li><Link to={"/orientationdays"}>Integration Day</Link></li>
												<li><Link to={"/firstday"}>First Day of School</Link></li>
												<li><Link to={"/baisakhi"}>BAISAKHI</Link></li>
												<li><Link to={"/earthday"}>Earth Day</Link></li>
												<li><Link to={"/mothersday"}>Mothers Day</Link></li>
												<li><Link to={"/poolparty"}>Pool Party</Link></li>
												<li><Link to={"/summercamp"}>Summer Camp</Link></li>
												<li><Link to={"/yogaday"}>International Yoga Day</Link></li>
												<li><Link to={"/independenceday"}>Independence Day</Link></li>
												<li><Link to={"/rakhi"}>Rakhi</Link></li>
												<li><Link to={"/grandsparentsday"}>Grandparents Day</Link></li>
												<li><Link to={"/gandhijayanthi"}>Gandhi Jayanthi</Link></li>
												<li><Link to={"/dasara"}>Dussehra</Link></li>
												<li><Link to={"/garba"}>Garba</Link></li>
												<li><Link to={"/halloweenparty"}>Halloween Party</Link></li>

												{/* <li><Link to={"/blog-classic-grid"}>Classic</Link></li> */}
											</ul>
										</li>
											
										<li><Link to={"/contactus"}>Contact Us</Link></li>

									</ul>	

									{/* <div className="right m-b2">
										<a href="https://wa.me/917019294542" target="_blank" ><i ><img src={Whatsapp} alt="" /></i></a>
									</div> */}
								</div>
							</div>
						</div>
					</div>
					{/* Main header END */}
				</header>
			</Fragment>
		)
	}
}
export default Header;