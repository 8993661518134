import React, {Component} from 'react';
import {Link} from 'react-router-dom';	
import bnr1 from './../../images/main-slider/slide5.jpg';
import bnr3 from './../../images/main-slider/slide6.jpg';
import bnr2 from './../../images/main-slider/slide7.jpg';
import Carousel from 'react-bootstrap/Carousel'

const carouselBlog = [
	{id:1, image: bnr1, header1: " Welcome ", header4: " To ", header5: " The Gurukul ", header6: " Preschool ", para: "We here at Gurukul have designed a wide range of activities to make children understand the practical application of initial number concepts." },	
	{id:2, image: bnr2, header5: " The Gurukul ", header6: " Preschool ", para: "We believe that quality child care fosters the healthy growth and development of the child. Each child is unique, which is why educators at Gurukul are trained to give individual attention." },	
	{id:3, image: bnr3, header5: " The Gurukul ", header6: " Preschool ", para: "To become a trusted partner in the field of early(child) education by collaborating with parents, educationists and employing a balanced teaching methodology" },	
];		

class BannerSlider extends Component{
			
	render(){
		return(
			<Carousel
				indicators= {false}
				controls={true}
				className="owl-slider owl-carousel owl-theme owl-btn-center-lr dots-none" 
				autoplay={true}
			>
				{carouselBlog.map((item,index)=>(	
					<Carousel.Item key={index}>
						<div className="slide-item">
							<div className="slide-item-img"><img src={item.image}  alt=""/></div>
							<div className="slide-content">
								<div className="slide-content-box container">
									<div className={item.id == 1 ? "slide-content-area text-center" : "slide-content-area"}>
										{item.header1  && <h2 className="slider-title ">{item.header1}</h2>}
										{/* <h2 className="slider-title">{item.header2}</h2>
										<h2 className="slider-title">{item.header3}</h2> */}
										{item.header4  && <h2 className="slider-title ">{item.header4}</h2>}
										{item.header5  && <h2 className="slider-title ">{item.header5}</h2>}
										{item.header6  && <h2 className="slider-title">{item.header6}</h2>}
										{/* <p> {item.para} </p> */}
										<Link to={"./contactus"} className="btn btn-md kids-btn radius-xl">Join us</Link>
									</div>
								</div>
							</div>	
						</div>	
					</Carousel.Item>	
				))}	
			</Carousel>
		)
	}
}

export default BannerSlider;