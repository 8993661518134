import React,{Component} from 'react';
import Slider from "react-slick";
import {Link} from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox'; 
import { SRLWrapper, useLightbox } from 'simple-react-lightbox';

//images
import gallery1 from './../../images/gallery/img1.jpeg';
import gallery2 from './../../images/gallery/img2.jpeg';
import gallery3 from './../../images/gallery/img3.jpeg';
import gallery4 from './../../images/gallery/img4.jpeg';
import gallery5 from './../../images/gallery/img5.jpeg';
import gallery6 from './../../images/gallery/img6.jpeg';
import gallery7 from './../../images/gallery/img7.jpeg';
import gallery8 from './../../images/gallery/img8.jpeg';
import gallery9 from './../../images/gallery/img9.jpeg';
import gallery10 from './../../images/gallery/img10.jpeg';
import gallery11 from './../../images/gallery/img11.jpeg';
import gallery12 from './../../images/gallery/img12.jpeg';
import gallery13 from './../../images/gallery/img13.jpeg';
import gallery14 from './../../images/gallery/img14.jpeg';
import gallery15 from './../../images/gallery/img15.jpeg';
import gallery16 from './../../images/gallery/img16.jpeg';
import gallery17 from './../../images/gallery/img17.jpeg';
import gallery18 from './../../images/gallery/img18.jpeg';
import gallery19 from './../../images/gallery/img22.JPG';
import gallery20 from './../../images/gallery/img23.JPG';
import gallery21 from './../../images/gallery/img24.JPG';
import gallery22 from './../../images/gallery/img25.JPG';
import gallery23 from './../../images/gallery/img26.JPG';
import gallery24 from './../../images/gallery/img27.JPG';
import gallery25 from './../../images/gallery/img28.jpeg';
import gallery26 from './../../images/gallery/img29.jpeg';


//Light Gallery on icon click 
const Iconimage = props => {
	const { openLightbox } = useLightbox()
	return (
		<Link to={"#"}
			onClick={(e) => {
				e.preventDefault()
				openLightbox(props.imageToOpen)
			}} className="check-km" >
			<i className="fa fa-search icon-bx-xs"></i>
		</Link>
	)
}

const latestBlog = [
	{ images: gallery19 }, { images: gallery20 }, { images: gallery21 },
	{ images: gallery22 }, { images: gallery23 }, { images: gallery24 },
	{ images: gallery4 }, { images: gallery5 }, { images: gallery6 },
	{ images: gallery7 }, { images: gallery8 }, { images: gallery9 },
	{ images: gallery17 }, { images: gallery11 }, { images: gallery12 },
	{ images: gallery13 }, { images: gallery14 }, { images: gallery15 },
	{ images: gallery1 }, { images: gallery2 }, { images: gallery3 },
	{ images: gallery10 }, { images: gallery16 }, { images: gallery18 },
	{ images: gallery25 }, { images: gallery26 },
]
class GallerySlider extends Component{	
	constructor(props) {
		super(props);
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
	}
	next() {
		this.slider.slickNext();
	}
	previous() {
		this.slider.slickPrev();
	}
	render(){
		var settings = {		
			arrows: false,
            slidesToShow: 3,			
            infinite: true,
			autoplay: true,
			responsive: [
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 3,
				  }
				},
				{
				  breakpoint: 991,
				  settings: {
					slidesToShow: 3,
				  }
				},
				{
				  breakpoint: 576,
				  settings: {
					slidesToShow: 1,
				  }
				}
			]
        };
		return(
			<>	
				<SimpleReactLightbox>
					<SRLWrapper >
						<div className="sprite-nav">
							<Slider className="gallery-carousel owl-carousel owl-theme dots-none " ref={c => (this.slider = c)} {...settings}>
								{latestBlog.map((item, index) => (
									<div className="item p-3" key={index}>
									 <div className="dlab-box frame-box">
										<div className="dlab-thum dlab-img-overlay1"> 
												<img src={item.images} alt="" />
											<div className="overlay-bx">
												<div className="overlay-icon"> 
														<Iconimage imageToOpen={index} /> 
												</div>
											</div>
										</div>
									</div>
								</div>
								))}	
							</Slider>	
							<div className="owl-nav" style={{ textAlign: "center" }}>
								<div className=" owl-prev " onClick={this.previous}/>
								<div className="owl-next " onClick={this.next}/>
							</div>
						</div>	
					</SRLWrapper>
				</SimpleReactLightbox>	
			</>	
		)
	}
}
export default GallerySlider;