import React,{Component} from 'react';
import Slider from "react-slick";
import grid1 from './../../images/testimonials/pic1.jpeg';
import grid2 from './../../images/testimonials/pic2.jpeg';
import grid3 from './../../images/testimonials/pic3.jpeg';
import grid4 from './../../images/testimonials/pic4.jpeg';


const latestBlog = [
	{ image: grid1, name: "Seema Chopra", para: "Best preschool in dwarka.very good friendly and supporting teacher staff. it is a great place to study very safe and lovely environment. if you want to that your child grow and achieve success in future then you should take first step as The Gurukul preschool."	 },
	{ image: grid2, name: "Neeraj Soni", para: "Well equipped  and very good facilities in School  Campus, very experienced Principal and have great knowledge. Best Play School in Dwarka." },
	{ image: grid3, name: "Heena Khan", para: "The Gurukul preschool is incredible.!Very good friendly and supporting teacher, with good curriculum.Can see very positive changes in my son and very happy to see his overall development.Thank you very much to each n every faculty members of The Gurukul preschool" },
	{ image: grid4, name: "Sarthak Saini", para: "The school is well maintained and the teachers are very professional. They take great care of the children and are extremely living towards them. Overall it’s surrounded by great energy and positivity." },
	
]
class TestiMonialSlider extends Component{	
	constructor(props) {
		super(props);
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
	}
	next() {
		this.slider.slickNext();
	}
	previous() {
		this.slider.slickPrev();
	}
	render(){
		var settings = {		
			arrows: false,
            slidesToShow: 1,			
            infinite: true,
			autoplay: true,
			responsive: [
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 1,
				  }
				},
				{
				  breakpoint: 991,
				  settings: {
					slidesToShow: 1,
				  }
				},
				{
				  breakpoint: 576,
				  settings: {
					slidesToShow: 1,
				  }
				}
			]
        };
		return(
			<>	
				<div className="sprite-nav">
					<Slider className="client-carousel owl-carousel owl-theme  " ref={c => (this.slider = c)} {...settings}>
						{latestBlog.map((item, index) => (
							<div className="item" key={index}>
								<div className="client-box">
									<div className="testimonial-detail clearfix">
										<div className="testimonial-pic radius">
											<img src={item.image} width="100" height="100" alt="" />
										</div>
										<h5 className="testimonial-name m-t0 m-b5">{item.name}</h5> 
										{/* <span>Client</span>  */}
									</div>
									<div className="testimonial-text">
										<p>{item.para}</p>
									</div>
								</div>	
							</div>
						))}	
					</Slider>		
					<div className="owl-nav" style={{ textAlign: "center" }}>
						<div className=" owl-prev " onClick={this.previous}/>
						<div className="owl-next " onClick={this.next}/>
					</div>
				</div>	
			</>	
		)
	}
}
export default TestiMonialSlider;