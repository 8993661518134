import React,{Fragment,Component} from 'react';
import {Link} from 'react-router-dom';
import SimpleReactLightbox,{SRLWrapper,useLightbox} from 'simple-react-lightbox'; 
import Masonry from 'react-masonry-component';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import PageTitle from '../Layout/PageTitle';

//images
import gallery1 from './../../images/gallery/img1.jpeg';
import gallery2 from './../../images/gallery/img2.jpeg';
import gallery3 from './../../images/gallery/img3.jpeg';
import gallery4 from './../../images/gallery/img4.jpeg';
import gallery5 from './../../images/gallery/img5.jpeg';
import gallery9 from './../../images/gallery/img9.jpeg';
import gallery10 from './../../images/gallery/img10.jpeg';
import gallery11 from './../../images/gallery/img11.jpeg';
import gallery12 from './../../images/gallery/img12.jpeg';
import gallery13 from './../../images/gallery/img13.jpeg';
import gallery19 from './../../images/gallery/img22.JPG';
import gallery20 from './../../images/gallery/img23.JPG';
import gallery21 from './../../images/gallery/img24.JPG';
import gallery22 from './../../images/gallery/img25.JPG';
import gallery23 from './../../images/gallery/img26.JPG';
import gallery24 from './../../images/gallery/img27.JPG';
import gallery25 from './../../images/gallery/img28.jpeg';
import gallery26 from './../../images/gallery/img29.jpeg';
import gallery27 from './../../images/gallery/img30.jpg';
import gallery28 from './../../images/gallery/img31.jpg';
import gallery29 from './../../images/gallery/img32.jpg';
import gallery30 from './../../images/gallery/img33.jpg';
import gallery31 from './../../images/gallery/img34.jpg';
import gallery32 from './../../images/gallery/img35.jpg';
import gallery33 from './../../images/gallery/img38.jpg';
import gallery34 from './../../images/gallery/img39.jpg';
import gallery38 from './../../images/gallery/img46.jpg';
import gallery39 from './../../images/gallery/img47.jpg';
import gallery40 from './../../images/gallery/img48.jpg';
import gallery41 from './../../images/gallery/img50.jpg';

import gallery42 from './../../images/gallery/IMG-1.jpg';
import gallery43 from './../../images/gallery/IMG-2.jpg';
import gallery44 from './../../images/gallery/IMG-3.jpg';
import gallery45 from './../../images/gallery/IMG-4.jpg';
import gallery46 from './../../images/gallery/IMG-5.jpg';
import gallery47 from './../../images/gallery/IMG-6.jpg';
import gallery48 from './../../images/gallery/IMG-7.jpg';
import gallery49 from './../../images/gallery/IMG-8.jpg';
import gallery50 from './../../images/gallery/IMG-9.jpg';
import gallery51 from './../../images/gallery/IMG-10.jpg';
import gallery52 from './../../images/gallery/IMG-11.jpg';
import gallery53 from './../../images/gallery/IMG-12.jpg';
import gallery54 from './../../images/gallery/IMG-13.jpg';

const galleryBlog =[
	
	{ images: gallery51 }, { images: gallery52 }, { images: gallery53 },
	{ images: gallery54 }, 
	{ images: gallery19 }, { images: gallery20 }, { images: gallery21 },
	{ images: gallery22 }, { images: gallery23 }, { images: gallery24 },
	{ images: gallery4 }, { images: gallery5 }, { images: gallery13 }, 
	{ images: gallery9 }, { images: gallery11 }, { images: gallery12 },
	{ images: gallery1 }, { images: gallery2 }, { images: gallery3 },
	{ images: gallery10 }, { images: gallery25 }, { images: gallery26 },
	{ images: gallery27 }, { images: gallery28 }, { images: gallery29 },
	{ images: gallery30 }, { images: gallery31 }, { images: gallery32 },
	{ images: gallery33 }, { images: gallery34 }, { images: gallery38 },
	{ images: gallery39 }, { images: gallery40 }, { images: gallery41 },
	{ images: gallery42 }, { images: gallery43 }, { images: gallery44 },
	{ images: gallery45 }, { images: gallery46 }, { images: gallery47 },
	{ images: gallery48 }, { images: gallery49 }, { images: gallery50 },
	
];
// Masonry section
const masonryOptions = {                                          
    transitionDuration: 0
};
const imagesLoadedOptions = { background: '.my-bg-image-el' }
// Masonry section end

//Light Gallery Icon
const Iconimage = props => {
	const { openLightbox } = useLightbox()
	return (
		<Link to={"#"}
			onClick={(e) => {
				e.preventDefault()
				openLightbox(props.imageToOpen)
			}} className="check-km" >
			<i className="fa fa-search icon-bx-xs"></i>
		</Link>
	)
}

class Gallery extends Component{
	render(){
		return(
			<Fragment>
				<Header />
				<div className="page-content">
					<PageTitle  motherMenu="Gallery"  activeMenu="Gallery" />
					<div className="content-block">
						<div className="section-full content-inner">
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry">Gallery of our classes</h2>
									{/* <p>We provide three classes with nine to twenty children each aged twelve months to six years of age.</p> */}
								</div>
								<SimpleReactLightbox>
									<SRLWrapper>
										<div className="clearfix" id="lightgallery">
											<ul id="masonry" className="dlab-gallery-listing gallery-grid-4 gallery">
												<Masonry
													className={'my-gallery-class'} 
													options={masonryOptions} 
													disableImagesLoaded={false} 
													updateOnEachImageLoad={false} 
													imagesLoadedOptions={imagesLoadedOptions} // default {}
												>
													{galleryBlog.map((item,index)=>(
														<li className="web design card-container col-lg-4 col-md-6 col-sm-6 wow zoomIn"  key={index}>
															<div className="dlab-box frame-box m-b30">
																<div className="dlab-thum dlab-img-overlay1 ">
																	<img src={item.images} alt="" /> 
																	<div className="overlay-bx">
																		<div className="overlay-icon"> 
																			<Iconimage imageToOpen={index} />
																		</div>
																	</div>
																</div>
															</div>
														</li>
													))}
												</Masonry>		
											</ul>
										</div>
									</SRLWrapper>	
								</SimpleReactLightbox>
							</div>
						</div>
					</div>
				</div>	
				<Footer />
			</Fragment>
		)
	}
}
export default Gallery;