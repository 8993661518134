import React,{Component} from 'react';			

export default class ThemeButton extends Component{
	render(){
		return(
			<div className='fixedButton'>
				<a href="https://wa.me/919700700745" target="_blank" class="  theme-btn  bt-whatsapp"><i class="fa fa-whatsapp size-48"></i><span>whatsapp</span></a>
				<a href="https://www.facebook.com/thegurukulpreschool/" target="_blank" class="  theme-btn bt-facebook"><i class="fa fa-facebook size-48"></i><span>facebook</span></a>
				<a href="https://instagram.com/the_gurukulpreschool?utm_medium=copy_link" target="_blank" class="  theme-btn bt-instagram"><i class="fa fa-instagram size-48"></i><span>instagram</span></a>
				
			</div>

		)
	}	
}