import React,{Fragment, Component} from 'react';
import {Link} from 'react-router-dom';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import BannerSlider from '../Element/BannerSlider';
import FromSlider from '../Element/FromSlider';
import TestiMonialSlider from '../Element/TestiMonialSlider';
import TeacherSlider from '../Element/TeacherSlider';
import GallerySlider from '../Element/GallerySlider';
import PopupModal from '../Element/PopupModal';

import icon1 from './../../images/icon/icon1.jpg';
import icon2 from './../../images/icon/icon2.jpg';
import icon3 from './../../images/icon/icon3.jpg';
import icon4 from './../../images/icon/icon4.jpg';
import bgimg1 from './../../images/line.png';
import bgimg3 from './../../images/line2.png';
import bgimg2 from './../../images/background/bg1.jpg';
import bnr3 from './../../images/background/bg11.jpg';

const iconBlog = [
	{ image: icon1 , title1: 'To Think Creatively', title2: 'and Create' },	
	{ image: icon2 , title1: 'To Feel Fne and to', title2:'Understand Emotions' },	
	{ image: icon3 , title1: 'To be Independent', title2: 'and Active' },	
	{ image: icon4 , title1: 'To Apply', title2:'Knowledge in Life' },	
];
const iconBlog2 = [
	{ icon:  <i className="flaticon-rattle text-blue" />, title: 'Infants', },	
	{ icon:  <i className="flaticon-bricks text-green" />, title: 'I myself', },	
	{ icon:  <i className="flaticon-puzzle text-orange" />, title: 'Goodie',  },	
];

function BackgroundBlog2() {
	return (
		<>
			<div className="container">
				<div className="row">
					<div className="col-lg-12 col-md-12 col-sm-12 col-12  about-text" style={{ visibility: "visible", animationDuration: "2s", animationDelay: "0.2s", animationName: "fadeIn" }}>
						<div className="section-head text-center">
							<h4 style={{ color:"#bc2a8d"}}></h4>
							<h2 style={{ color: "#bc2a8d" }}> <br /><span className="text-blue"></span></h2>
							{/* <Link to={"./contactus"} className="btn btn-md radius-xl">Read More</Link> */}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

class Index1 extends Component{
	render(){
		return(
			<Fragment>
				<Header />
				<div className="page-content bg-white">
					{ /* Slider Banner */ }
						<BannerSlider />
					{ /* Slider Banner */}
					<PopupModal />
					<div className="content-block">
						{/*  About Us */}
							<div className="section-full bg-white content-inner-1 text-center">
								<div className="container">
									<div className="section-head">
									<h2 className="head-title text-secondry">Welcome to The Gurukul Preschool</h2>
										<p>Fill your child's childhood with the joy of learning!</p>
									</div>
									{/* <div className="row">
										{iconBlog.map((data, index)=>(
											<div className="col-lg-3 col-md-6 col-sm-6 col-12" key={index}>
												<div className="icon-bx-wraper sr-iconbox m-b20">
													<div className="icon-lg m-b20">
														<Link to={"#"} className="icon-cell"><img src={data.image} alt=""/></Link>
													</div>
													<div className="icon-content">
														<h6 className="dlab-tilte">{data.title1}<br/>{data.title2}</h6>
													</div>
												</div>
											</div>
										))}
									</div> */}
								</div>
							</div>
						{/*  About Us End*/}
						<div className="section-full bg-white content-inner-2 about-box" style={{backgroundImage:"url(" + bgimg1 +")",  backgroundSize:"contain",backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
							<div className="container">
								<div className="row">
									<div className="col-lg-6 col-md-6 col-sm-12">
										<div className="section-head">
											<h2 className="head-title text-secondry">Our Vision</h2>
											<p>
												Our vision is to bring out the best in every child by developing in each student a sense of probity, self-confidence and emotional, mental as well as physical well being. We are committed to create a safe, fun-filled environment where children acquire intellectual acuity, embrace diversity, lead lives of personal honour and act with generosity and compassion. 
											</p>
											
											
										</div>

									</div>
									<div className="col-lg-6 col-md-6 col-sm-12">
										<div className="section-head">
									<h2 className="head-title text-secondry">Our Mission</h2>
									<p>
										The Gurukul aims to instil spirit of knowledge, wisdom and fervour in children for learning creative and innovative things and outshine in the competitive world. To foster in each student a strong sense of moral integrity and focusing on appropriate behaviour commensurating with time and place is our objective.
										</p>
										</div>

									</div>
									{/* <Link to={"/contactus"} className="btn btn-md radius-xl" >Learn more</Link> */}
									{/* <div className="col-lg-5 col-md-12 col-sm-12 col-12">
										{iconBlog2.map((item, index)=>(
											<div className="icon-bx-wraper left" key={index}>
												<div className="icon-lg m-b20"> <span className="icon-cell">{item.icon}</span> </div>
												<div className="icon-content">
													<h2 className="dlab-tilte">{item.title}</h2>
													<p>Lectus placerat a ultricies a,interdum donec eget metus auguen u Fusce mollis imperdiet interdum donec eget metus.</p>
												</div>
											</div>
										))}
									</div> */}
								</div>
							</div>
						</div>
						<div className="section-full bg-white content-inner-2 about-content bg-img-fix" style={{ backgroundImage: "url(" + bnr3 + ")" }}>
							<BackgroundBlog2 />
						</div>
						<div className="section-full bg-white content-inner-1">
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry">Events and Celebrations</h2>
									{/* <p>We provide three classes with nine to twenty children each aged twelve months to six years of age.</p> */}
								</div>
								<GallerySlider />
							</div>
						</div>
						<div className="section-full bg-white content-inner-1" style={{backgroundImage:"url("+ bgimg3 +")", backgroundSize:"contain", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry">About the Teachers</h2>
									<p>Our staff represent collective behaviour of the core values. We value creative freedom, mutual respect and trust.  Our focus for quality learning is linked to our philosophy that school should be safe, fun, enjoyable and open to accept challenges .</p>
								</div>
								<TeacherSlider />
							</div>
						</div>
						<div className="section-full bg-white content-inner-1">
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry">Testimonials</h2>
									{/* <p>We have an excellent teacher to child ratio at our Kindergarten to ensure that each child receives the attention he or she needs</p> */}
								</div>
								<TestiMonialSlider />
							</div>
						</div>
						<div className="section-full bg-white content-inner">
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry">Blog</h2>
									{/* <p>We have an excellent teacher to child ratio at our Kindergarten to ensure that each child receives the attention he or she needs</p> */}
								</div>
								<FromSlider />
							</div>
						</div>
					</div>
				</div>
				<Footer />
				
			</Fragment>
			
		)
	}
}

export { BackgroundBlog2, };
export default Index1;