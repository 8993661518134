import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {Link} from 'react-router-dom';
import logo from './../../images/logo.png';
const PopupModal = (props) => {

    const [showPopupModel, setShowPopupModel] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowPopupModel(true);
        }, 2000)
    }, []);

    return (
        <Modal
            centered={true}
            
            isOpen={showPopupModel}
            toggle={() => {
                setShowPopupModel(false);
            }}
            >
            {/* <ModalHeader toggle={() => {
                setShowPopupModel(false);
            }} tag="h4">
                Your Title
            </ModalHeader> */}
            <ModalBody>
                       
                {<div  >
                   
                    <div className="text-center">
                        <img src={logo} alt="" />
                        <p style={{color:"#b42e9e"}} className='mb-0 mt-2'> !!! **************** !!!</p>
                        <p style={{ color: "#b42e9e" }} className='mb-0 mt-2'>!!!Namaste!!!</p>
                        <h2 className=" text-secondry">2023-2024 <br/> <span>Admissions open</span></h2>
                        
                        

                        <Link to={"./contactus"} className="btn  kids-btn radius-xl">Enquire Now</Link>

                        <p style={{ cursor: "pointer" }} className='text-danger mb-0 mt-2'onClick={()=>{setShowPopupModel(false);}}><u>close</u></p>
                    </div>
                    
                </div>}
               
            </ModalBody>
            {/* <ModalFooter>
                
                        
                <button type="button" className="btn btn-light mr-2"
                    onClick={() => {
                        setShowPopupModel(false);
                    }}>
                    Close
                </button>
                <button type="submit" className="btn btn-success save-event"> Save </button>
                       
                   
            </ModalFooter> */}
        </Modal>

        

    );

}

export default (PopupModal);